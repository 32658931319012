 /* ************ flexibilni galerie (pocet obrazku se prizpusobi sirce stranky) **************** */

@import "W:/ASPINCLUDE/vismoWeb5/html/ecco2/ecco";

.nahledy {
	@extend %sf;
	padding: 15px 0;
	margin: 0;
	list-style-type: none;
	clear: both;
	display: flex;
	flex-wrap: wrap;

	@include msie-only {
		display: block;
	}

	li {
		@extend %reset;
		text-align: center;
		margin-bottom: 15px;
	}

	@include link {
		text-decoration: none;
	}

	a {
		display: block;
		cursor: pointer;
		padding-top: 13px;
	}

	img {
		vertical-align: top;
		margin: 0 auto;
		width: 80%;
		height: auto;
	}
	
	span {
		display: block;
		padding: 8px;
		float: none;
	}

	li > a {
		width: 90%;
	}

	div {
		height: 100%;
		display: block;
		
		> a {
			@extend %border-box;
			transition: background-color .2s, border-color .2s;
		}
	}

	&:not(.pridanytext) div > a {
		height: 100%;
	}
}


/**********************
*	PŘEHLEDY GALERIÍ
**********************/

.galerie-2016 {
	.ui {
		@extend %sf;
		@extend %reset-list;
		margin-bottom: 30px;
		margin-top: 20px;

		li {
			@extend %border-box;
			@extend %reset;
			clear: none;
			margin-bottom: 35px;

			img {
				margin: 0 0 8px 0;
				float: none;
				display: table;
			}

			a {
				display: block;
			}

			&.noimage {
				a::before {
					content: "";
					display: block;
					height: 0;
					margin-bottom: 8px;
					padding-bottom: 60%;
					background: #cccccc url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50%;
				}
			}
		}
	}
}


// @media Unknown {
// 	.nahledy {
// 		display: inline-block;

// 		li {
// 			vertical-align: top;
// 		}
		
// 		li a {
// 			display: inline-block;
// 			vertical-align: top;
// 		}
// 	}
// }

.pridanytext p {
	margin: 0;
	padding: 8px;
	
	a {
		height: auto;
		min-height: 10px;
		text-decoration: underline;
		padding-top: 0;
		border: 0 !important;
		background: none !important;
	}

	@include link-over {
		text-decoration: none;
	}
}
