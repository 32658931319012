/*-------------zakladni styly pro stranky dalsi urovne--------------*/

@import "W:/ASPINCLUDE/vismoWeb5/html/ecco2/ecco";
@import "formulare";
@import "strom";

.zarovnani {
	font-size: rem(15px);

	* {
		vertical-align: middle;
		display: inline-block;
	}

	.strzpet,
	.strvpred,
	span a {
		height: 29px;
	}
}

// .strlistovani {
// 	display: inline;
// 	margin: 0;
// 	position: relative;

// 	strong {
// 		color: #c52a33;
// 	}
	
// 	span {
// 		display: inline;
// 		position: relative;
// 		top: 0;
// 		margin-bottom: -4px;
// 	}
	
// 	a {
// 		display: inline;
// 	}
	
// 	img {
// 		margin-bottom: -4px;
// 	}
// }

.zarovnani p {
	@extend %reset;
	display: inline;
}

.popis {
	margin-bottom: 10px;
	line-height: 150%;

	#osnova & {
		margin-bottom: 30px;
	}
}

.obsah hr.grafika {
	display: none;
}

.kontext2 {
	padding-bottom: 8px;
	padding-top: 50px;

	h3 {
		@extend %vh;
	}
}

.chskryt {
	display: none !important;
}

.perex {
	position: relative;
	//font-weight: bold;
	padding: 0 0 15px 1px;
}

#celek .dpopis {
	padding-top: 60px;
  line-height: 1.8;
}

.obsah .obsah .popis {
	padding: 0 0 15px 0;
}

.stav,
.rozbalit,
.zobrazit,
.volba,
.dodatek,
.stranka {
	margin: 15px 0;
	padding: 0;
}

.pataprvku {
	margin: 15px 0 30px 0;
}

#zahlavi .zobrazeno {
	font-weight: bold;
	clear: none;
	margin: 0 0 15px 0;
}

.zobrazit,
.volba {
	font-weight: bold;
}

.zobrazit a,
.volba a {
	padding: 0 5px;
}

.unp {
	@extend %reset;
	//float: left;
	position: relative;
	top: 0;
	z-index: 10;

	span {
		float: left!important;
	}
	
	img {
		margin: 0 10px 10px 0;
	}
}

@include bp("< 500px") {
	.unp {
		width: 100%;
		clear: both;
		float: none;
		margin-bottom: 15px;

		a::after {
			content: "";
			font-size: 0;
			display: table;
			width: 100%;
			clear: both;
		}
		
		img {
			float: none;
		}
	}

	#unpobtekane {
		margin-left: 0 !important;
	}
}

.velkaupoutavka {
	span {
		float: left;
	}
	
	img {
		margin: 10px 10px 10px 0;
	}
}

#stred > .obsah {
	position: relative;
}

// #celek div.obsah div.obsah {
// 	padding: 0 !important;
// 	margin: 0 !important;
// 	border: 0 !important;
// 	position: static !important;
// }

.adresare,
.dok,
.dok2,
.formulare,
.galerie,
.kategorie,
.osnovy,
.odkazy,
.utvary,
.aktual,
.frm {
	position: relative;
	width: auto;
	overflow: hidden;
	text-align: left;
}

.ui {
	clear: left;
	@extend %reset-list;
	
	li {
		position: relative;
		clear: left;
		padding: .12em 0 0 15px;
		margin: 0;
		// background-repeat: no-repeat;
		// background-position: -3px 0.2em;

		&::after {
			content: "";
			font-size: 0;
			display: table;
			clear: both;
		}
	}

	> li {
		width: auto;
	}
	
	strong img {
		margin: 3px 18px 4px 0;
	}
	
	// .galerie & strong img,
	// .bodkazy & strong img {
	// 	margin-left: -20px;
	// }
	
	img {
		float: left;
		margin: 7px 15px 6px 0;
	}
	
	div {
		padding: 5px 0 0 0;
	}
	
	.odkazy & li.bi,
	.utvary & li.ou {
		background-image: none;
		list-style-type: none;
	}

	ul li strong {
		font-weight:normal;
	}
}

.navratove {
	margin-bottom: 10px;
} // odkazy ve fotogalerii na stranky, z nichž bylo možne prijit na galerii, vlozeno u nahledu i u zvetseniny

dl {
	margin: 8px 0 0 0;
	padding: 0 0 0 1px;

	&.kontakty ul.ui {
		margin-left: 4px; //4px kvuli zarovnani s ostatnimi ul.ui
		
		ul {
			margin-left: 20px;
		}
	}
}

dl dl {
	margin: 8px 0 0 0;
}

dt {
	font-weight: bold;
	margin: 20px 0 0 0;
	padding: 0;
}

dd {
	padding: 3px 0 0 0;
	margin-left: 0;
}

dd ul.ui li {
	margin-top: 0;
}

.ui dd div {
	padding-top: 0;
	line-height: 130%;
}

ul.ui li dl {
	margin-left: 0;
}

.vlozenemenuobsah {
	margin: 0 0 0 15px;
}

#cse-search-results iframe {
	height: 1400px;
}

//@import "frm_prihlaseni";
@import "foto";

.fotobig {
	padding-bottom: 15px;

	img {
		line-height: normal;
	}
	
	.obr img {
		margin-bottom: 10px;
	}
}

#fgzvet .uvodprvku {
	margin-bottom: 15px;
}

#textovelistovani {
	margin-top: 15px;
	display: none;

	// a:first-child,
	// a:last-child {
	// }
	
	.aktivni {
		background-color: transparent !important;
	}
}

#listovani {
	position: relative;
	margin: 10px 0;
	border: 0;

	#prvni,
	#posledni,
	#zpetfg img {
		display: none;
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}

	#zpetfg {
		a::after {
			content: "zpět na galerii";
			vertical-align: middle;
			display: inline-block;
		}
	}
	
	a {
		text-decoration: none;
	}
}

.strlistovani,
#listovani {
	img {
		background-color: #bbbbbb;
		color: #000000;
	}

	.aktivni {
		img {
			background-color: #555555 !important;
			color: #FFFFFF;
		}

		&:hover img,
		&:focus img {
			background-color: #bbbbbb !important;
			color: #000000;
		}
	}
}


//@import "kalendar_akci";

.zadani-akce {
	fieldset {
		padding: 1em 1.5em 1.5em 1.5em;
		margin-bottom: 1.5em;
	}
}

@import "isvs";
@import "tabulka";
@import "formular";
@import "karta";
@import "ktg_vypis";


